import V from 'voUtils/V.js';
import QuoteTools from 'voUtils/QuoteTools.js';

import {
	doReq
}
from 'voUtils/RestEx.js';
import {
	fireQuote, fireUnitAction, fireCheckout
}
from 'voUtils/TagEx.js';
import {
	find, filter, isEmpty, oneIs
}
from 'voUtils/tools.js';
import DateEx from 'voUtils/DateEx.js';

export default {

	data: {
		bookingData: {
			adults: 2,
			children: 0,
			childrenAges: undefined,
			babys: 0,
			petsCount: 0,
			from: undefined,
			till: undefined
		},
		action: undefined,
		step: undefined, //'booking',
		priceIsUpdated: false,
		showPriceDetails: false,
		quote: {},
		error: undefined,
		preview: undefined,
		summary: undefined,
		selectedImageIndex: undefined,
		showAllProps: false,
		showFullFeedback: false
	},
	created: function () {
		this.calListeners = [];
		this.QuoteTools = QuoteTools;
		this.priceListeners = [];

		var params = new URLSearchParams(new URL(window.location).search);
		for (let [k, v] of params) {
			if (oneIs(k, 'ref_adults', 'ref_children', 'ref_babys', 'ref_from', 'ref_till')) {
				this.bookingData[k.substring(4)] = v;
			}
		}

		var hash = window.location.hash;
		if (hash) {
			hash = hash.substring(1);

			let url = new URL(hash, 'https://www.v-office.com');
			let params = new URLSearchParams(url.search);

			for (let p of params) {
				if ((p[0] == 'children' || p[0] == 'babys') && !this.areChildrenWelcome()) {
					//ignore not welcome
				} else if (p[0] == 'petsCount' && !this.arePetsWelcome()) {
					//ignore not welcome
				} else {
					this.bookingData[p[0]] = p[1];
				}
			}
		}


		this.updatePrices();
	},
	computed: {
		showBookingButtons: function () {
			return this.quote && this.quote.mandatory && !this.priceIsUpdated && isEmpty(this.quote.errors) && !this.action;
		},
		canDoNext: function () {
			return this.quote && this.quote.mandatory && !this.priceIsUpdated && isEmpty(this.quote.errors) && this.action;
		},
		periodLabel: function () {
			let label = '';
			if (this.bookingData.from) {
				label = this.voFormatDate(this.bookingData.from, 'withDay');
			}
			if (this.bookingData.till) {
				label += ' - ' + this.voFormatDate(this.bookingData.till, 'withDay');
			}

			return label;
		},
		guestLabel: function () {
			var sd = this.bookingData;
			var amount = ((sd.adults || 0) - 0) + ((sd.children || 0) - 0) + ((sd.babys || 0) - 0);
			var label = amount + ' ' + this.voMsgPl('guest.pl', amount);
			if (sd.petsCount) {
				label += ', ' + sd.petsCount + ' ' + this.voMsgPl('pet.pl', sd.petsCount);

			}
			return label;
		}


	},
	mounted: function () {
		const max = 3;
		const elements = document.getElementsByClassName("fdbck-entry");

		if (elements.length > 0) {
			if (elements.length >= max) {
				for (let i = 0; i < max; i++) {
					elements[i].style.display = "block";
				}
				for (let i = max; i < elements.length; i++) {
					elements[i].style.display = "none";
				}
			}
		}

		const moreFeedbacksBtn = document.getElementById("moreFeedbacksBtn");
		//console.log('maxFeedbacks::', max, 'Feedbacks:', elements.length);
		if (elements.length > 0) {
			if (elements.length > max) {
				document.getElementById("moreFeedbacksBtn").style.display = "block";
			} else {
				document.getElementById("moreFeedbacksBtn").style.display = "none";
			}
		}

		if (elements.length > 0) {
			moreFeedbacksBtn.addEventListener("click", function () {
				const hiddenElements = document.querySelectorAll(
					'.fdbck-entry:not([style*="display: block"])'
				);
				//console.log('hiddenElements length::' + hiddenElements.length);
				if (hiddenElements.length <= 0) {
					//document.getElementById("moreFeedbacksBtn").style.display = "none";
					const elements = document.getElementsByClassName("fdbck-entry");

					if (elements.length > 0) {
						if (elements.length >= max) {
							for (let i = 0; i < max; i++) {
								elements[i].style.display = "block";
							}
							for (let i = max; i < elements.length; i++) {
								elements[i].style.display = "none";
							}
						}
					}

					$('html, body').animate({
						scrollTop: $("#feedbacks").offset().top + 150
					}, 20);
				}
				for (let i = 0; i < hiddenElements.length; i++) {
					hiddenElements[i].style.display = "block";
				}
				const visibleElements = document.querySelectorAll(
					'.fdbck-entry:not([style*="display: none"])'
				);

			});
		}


		this.makeBookingPanelSticky();
	},

	methods: {
		checkVoucherCode: function (code) {
			if (code) {
				if (this.voucher && code === this.voucher.code) {
					return true;
				} else {
					return false;
				}

			} else {
				return true;
			}
		},
		canShowGoogleMaps: function () {
			var GoogleMaps = localStorage.getItem("allowGoogleMaps");
			if (GoogleMaps === 'true') {
				return true;
			}
			return false;
		},
		getPrices: function (done) {
			if (this.prices) {
				console.log('Prices schon da');
				done(this.prices);
			} else {
				this.priceListeners.push(done);

				if (this.priceListeners.length === 1) {

					var req = {
						id: this.getUnitId()
					};

					var params = {
						'noCache': true
					};

					doReq('getPricelist', req, params)
						.then((res) => {
							this.prices = res.prices;

							for (let l of this.priceListeners) {
								l(res.prices);
							}
							this.priceListeners.length = 0;
						});


				}
			}
		},
		makeBookingPanelSticky: function () {
			$(window).scroll(function () {
				if ($(window).width() > 1199) {
					height = $(window).scrollTop();
					if (height > 500 && height < 2000) {
						$('#stickysidebar').addClass('affix');
					} else {
						$('#stickysidebar').removeClass('affix');
					}
				}

			});
		},
		addLine: function (quote, sl) {
			QuoteTools.addLine(quote, sl);
			this.$forceUpdate();
		},
		removeLine: function (quote, sl) {
			QuoteTools.removeLine(quote, sl);
			this.$forceUpdate();
		},
		clearPrices: function () {
			this.quote = {};
			this.summary = undefined;
			this.preview = undefined;
			this.previewQuote = undefined;
		},

		updatePrices: function () {

			var data = this.bookingData;
			this.error = undefined;
			if (data.from && data.till) {

				this.priceIsUpdated = true;
				this.quote = {};

				var req = {
					fromdate: DateEx.interfaceFormat(data.from),
					tilldate: DateEx.interfaceFormat(data.till),
					adults: data.adults,
					children: data.children,
					babys: data.babys,
					petsCount: data.petsCount,
					unit: this.getUnitId()
				};

				req.includeServiceLimits = true;

				if (VOFFICE.quoteOptions) {
					Object.assign(req, VOFFICE.quoteOptions);
				}

				if (this.voucher) {
					req.voucherCode = this.voucher.code;
				}

				if (VOFFICE.abtest) {
					req.abtest = VOFFICE.abtest;
				}

				if (data.childrenAges) {
					req.childrenAges = data.childrenAges;
				}

				doReq('quotePrices', req)
					.then((res) => {
						this.quote = res.quote;
						this.priceIsUpdated = false;
						this.quote.optional.forEach(sl => {
							sl.optional = true;
							sl.amount = null;
							this.QuoteTools.updateTotal(sl);
						});
						this.quote.onsiteOptional.forEach(sl => {
							sl.onsiteOptional = true;
							sl.amount = null;
							this.QuoteTools.updateTotal(sl);
						});

						if (res.quote.mandatory) {
							this.summary = find(res.quote.mandatory, function (sl) {
								return sl.service.type === 'SUMMARY';
							});

							this.preview = filter(res.quote.mandatory, function (sl) {
								return !VOFFICE.hideTypeOnPricePreview || VOFFICE.hideTypeOnPricePreview.indexOf(sl.service.type) == -1;
							});

							if (!this.preview || this.preview.length < 2) {
								this.preview = undefined;
								this.previewQuote = undefined;
							} else {
								this.previewQuote = {
									mandatory: this.preview,
									hasDiscounts: this.quote.hasDiscounts
								};
							}

						}

						fireQuote(this.getCart());

					})
					.catch(e => {
						this.priceIsUpdated = false;
						this.error = e;
					});


			}

		},
		getCart: function () {
			return {
				action: this.action,
				quote: this.quote,
				booking: Object.assign({}, this.bookingData),
				unitId: this.getUnitId()
			};
		},

		goAction: function (action) {
			this.action = action;
			this.showPriceDetails = true;
			fireUnitAction(this.getCart());
		},

		putInCart: function () {
			this.step = 'booking';
			fireCheckout(this.getCart());
			window.scrollTo(0, 0);

		},
		cancelBooking: function () {
			this.step = undefined;
			this.makeBookingPanelSticky();
		},
		cancel: function () {
			this.action = undefined;
			this.showPriceDetails = false;

		},


		getUnitId: function () {
			if (!this.unitId) {
				let el = document.querySelector("[data-unit-id]");
				if (el) {
					this.unitId = parseInt(el.getAttribute('data-unit-id'), 10);
				}
			}
			return this.unitId;
		},
		areChildrenWelcome: function () {
			if (this.childrenWelcome == undefined) {
				let el = document.querySelector("[data-children-welcome]");
				if (el) {
					this.childrenWelcome = el.getAttribute('data-children-welcome') == '1';
				} else {
					this.childrenWelcome = false;
				}
			}
			return this.childrenWelcome;
		},
		arePetsWelcome: function () {
			if (this.petsWelcome == undefined) {
				let el = document.querySelector("[data-pets-welcome]");
				if (el) {
					this.petsWelcome = el.getAttribute('data-pets-welcome') == '1';
				} else {
					this.petsWelcome = false;
				}
			}
			return this.petsWelcome;
		},
		getCal: function (done) {
			if (this.cal) {
				done(this.cal);
			} else {
				this.calListeners.push(done);

				if (this.calListeners.length === 1) {

					var req = {
						unit: this.getUnitId()
					};

					doReq('getCal', req)
						.then((res) => {
							this.cal = res.cal;

							for (let l of this.calListeners) {
								l(res.cal);
							}
							this.calListeners.length = 0;

						});
				}
			}


		}
	},
	watch: {
		'bookingData.from': function () {
			if (!this.bookingData.till) {
				this.clearPrices();
			}
		}

	}

};